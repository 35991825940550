import service from "@/utils/dmsrequest";
import dmshttp from "@/utils/dmsrequest";
//获取渠道订单详情
export function getOrderDetail(id) {
  return service({
    url: "findPurchaseOrderDetail",
    data:{
      purchaseOrderId:id
    }
  });
}
export function getPurchaseOrderDetail(id) {
  return service({
    url: "getPurchaseOrderDetail",
    data:{
      purchaseOrderId:id
    }
  });
}


export function getImg(data) {
  return dmshttp({
    url: 'toOmsView',
    data
  })
}