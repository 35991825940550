import service from "@/utils/request";
import dmsService from "@/utils/dmsrequest";


//保存评价
export function saveEvaluate(data) {
  return service({
    method: "post",
    url: "/orderEvaluation/saveEvaluate.nd",
    data: data
  });
}
//获取评价
export function getEvaluate(data) {
  return service({
    method: "post",
    url: "/orderEvaluation/init.nd",
    data: data
  });
}

//获评价详情
export function getExamInfo(id) {
  return dmsService({
    url: "getPurchaseExamInfo",
    data: {
      orderId: id
    }
  });
}
// 取消物流
export function cancleLog(data) {
  return dmsService({
    url: 'omsOrderCancle',
    data
  })
}
// 订单物流信息
export function findOmsOrderStatus(data) {
  return dmsService({
    url: 'findOmsOrderStatus',
    data
  })
}
// 查看进度
export function getSalesOrderProcess(id) {
  return dmsService({
    url: 'getSalesOrderProcess',
    data: {
      salesOrderId: id,
      loginType: "CS"
    }
  })
}
// 补充快递单号/物流单号
export function addShopmentDetail(data) {
  return dmsService({
    url: 'addShopmentDetail',
    data
  })
}
// 获取物流公司列表
export function getListCompany(data) {
  return dmsService({
    url: 'getListCompany',
    data
  })
}
// 获取第三方物流单信息
export function getTracksList(data) {
  return dmsService({
    url: 'listTracks',
    data
  })
}
