import {
  getImg
} from "../details/api";
import {
  getPurchaseOrderProcess
} from "./api";
import {
  saveEvaluate,
  getEvaluate,
  sign
} from "./api";
import Clipboard from "clipboard";
import {
  addCart
} from "@/views/order/api";
import logisticsEvaluationModal from '@/views/order/components/logisticsEvaluationModal.vue'
import consulationModal from '@/views/order/components/consultationPopup.vue'
import {queryAppraise} from "@/views/order/progress/api";
import { findOmsOrderStatus } from "@/views/order/retailOrder/progress/api";
export default {
  name: "purchaseOrderprogress",
  components: {
    logisticsEvaluationModal,
    consulationModal
  },
  data() {
    return {
      showImg:true,
      pageLoadFlag: false,
      desc: ["非常不满意", "不满意", "一般", "满意", "非常满意"],
      breadcrumbData: [{
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/channelOrder/detail",
          name: "orderDetail",
          title: "订单详情"
        },
        {
          path: "/channelOrder/progress",
          name: "progress",
          title: "发货进度"
        }
      ],
      tabActiveIndex: 0,
      visible: false,
      orderDetail: {
        orderHeader: {}
      },
      evaluate: {
        productLevel: 3,
        deliveryLevel: 3,
        logisticsLevel: 3,
        evaluationContent: "",
        creator: "",
        createdDate: "",
        serviceLevel: 1,
        show: false, //是否显示评价
        finished: false //是否评价完成
      },
      tags: [{
          label: "结果满意",
          val: false
        },
        {
          label: "回复速度快",
          val: false
        },
        {
          label: "态度好",
          val: false
        }
      ],
      examInfo: {},
      erpList: [], //发货单列表
      isOuted: false, //是否是出库单
      selSapOrder: {
        sap: {},
        logistics: []
      }, //当前选中的套单
      // isDisabled:false,
      onluyOneMessage: false,
      imgURL: [],
      isHdyxActiveFlag:false, // 回单影像是否可点击 (初始化接口请求)
      modelConsulation: false,
      modellogisticsEvaluation: false,
      giveUpModal:true,
      reportModal:true,
      consultationList: [], // 咨询详情
      evaluationObj:null,
      mapUrl:'',
      key: new Date().getTime(),
      showDisplay:false,
      omsList:[]
    };
  },
  watch:{
    mapUrl: {
      handler(val) {
        this.key = new Date().getTime()
        this.mapUrl= val
      },
      immediate: true,
    }
  },
  computed: {
    testList() {
      // 当数据不需要完全显示的时候
      if(this.showDisplay == false){
        let testList = []; // 定义一个空数组
        // 先显示前三个
        if(this.consultationList.length > 3){
          for(var i = 0;i < 3;i++){
            testList.push(this.consultationList[i])
          }
        }else{
          testList = this.consultationList;
        }
        return testList; // 返回当前数组
      }
      else{//展示全部数据
        return this.consultationList;
      }
    },
    totalProd: function () {
      let total = 0;
      if (this.orderDetail && Array.isArray(this.orderDetail.orderLines)) {
        this.orderDetail.orderLines.forEach(item => {
          total += item.qty;
        });
      }
      return total;
    },
    totalPrice: function () {
      let total = 0;
      if (this.orderDetail && Array.isArray(this.orderDetail.orderLines)) {
        this.orderDetail.orderLines.forEach(item => {
          total += (item.qty * (item.basePrice * 100)) / 100;
        });
      }
      return total;
    },
    totalLoadVolume: function () {
      let total = 0;
      if (this.selSapOrder && Array.isArray(this.selSapOrder.sap.prods)) {
        this.selSapOrder.sap.prods.forEach(item => {
          total += (
              ((item.sap.bactualQty || 0) *
                  ((item.sap.volume || 0) * 1000)) /
              1000
          )
        });
      }
      return total;
    },
    totalAdvancePrice: function () {
      let total = 0;
      if (this.orderDetail && Array.isArray(this.orderDetail.orderLines)) {
        this.orderDetail.orderLines.forEach(item => {
          total += item.advancePrice;
        });
      }
      return total;
    }
  },


  mounted() {
    if (this.$route.query.id) {
      this.getDetail(this.$route.query.id);
      this.breadcrumbData[1].path = "/channelOrder/detail?id=" + this.$route.query.id;

    } else {
      this.getDetail("");
      this.$notification.warning({
        message: "订单号错误",
        duration: 2
      });
    }
  },
  methods: {
    // 查询咨询评价记录接口
    queryAppraise() {
      queryAppraise({
        orderCode: this.orderDetail.saleNum
      }).then(res => {
        // const { code, msg, type1, type3 } = res.data
        if(res.data.code == 0) {
          this.consultationList = res.data.type1
          if(JSON.stringify(res.data.type3) != '{}') {
            res.data.type3.tagNameList = res.data.type3.tagNames ? res.data.type3.tagNames.split(',') : []
            this.evaluationObj = res.data.type3
          }else {
            this.evaluationObj = null
          }
        }else {
          this.$message.warning( res.data.msg || '系统异常，请稍后重试')
        }
      })
    },
    openPJ(){
      this.modellogisticsEvaluation = true
    },
    openZX(){
      this.modelConsulation=true
    },
    // 投诉举报
    getInfo(formData){
      // console.log(form)
      this.getDetail(this.$route.query.id);
      // 提交咨询表单
    },
    getpjInfo(){
      this.getDetail(this.$route.query.id);
    },

    // 回单影像
    // requestImg(documentNum) {
    //   let data = {
    //     cisCode: this.$store.state.user.userInfo.customer.cisCode,
    //     documentNum
    //   }
    //   getImg(data).then(res => {
    //     if(res.data){
    //       var realData = JSON.parse(res.data);
    //       if (realData.data) {
    //         this.imgURL = realData.data;
    //         this.onluyOneMessage = true;
    //       } else {
    //         this.$message.warning('暂无回单影像');
    //       }
    //     }else{
    //       this.$message.warning('暂无回单影像');
    //     }
    //   })
    // },
    // 回单影像
    requestImg(documentNum) {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        documentNum
      }
      getImg(data).then(res => {
        if (res.data) {
          var realData = JSON.parse(res.data);
          if (realData.data) {
            this.imgURL = realData.data;
            this.isHdyxActiveFlag = true;
          } else {
            this.isHdyxActiveFlag = false;
          }
        } else {
          this.isHdyxActiveFlag = false;
        }
      })
    },
    openImg(){
      if(this.isHdyxActiveFlag){
        this.onluyOneMessage = true;
      }else{
        this.$message.warning('暂无回单影像');
      }
    },
    // 加入购物车
    addCart(proid, orgid) {
      let data = {
        productId: proid,
        orgId: orgid,
        num: 1
      };
      addCart(data)
        .then(res => {
          if (res.data.code == 0) {
            this.$message.success("加入购物车成功");
          } else {
            this.$message.warning(res.data.msg);
          }
        })
        .catch(error => {
          this.$message.warning("加入购物车失败");
          console.log("加入购物车", error);
        });
    },
    getProdImg() {
      return (
        this.selSapOrder.sap.materialGroup +
        "/" +
        this.selSapOrder.sap.productCode +
        "/180-180/" +
        this.selSapOrder.sap.picture
      );
    },
    getProdErrorImg() {
      return (
        "/assets/new/images/product/" +
        this.selSapOrder.sap.materialGroup +
        ".jpg"
      );
    },
    sumitEvaluate() {
      const params = {
        erpId: this.selSapOrder.id,
        orderId: this.selSapOrder.orderId,
        orgId: this.selSapOrder.orgId,
        productLevel: this.evaluate.productLevel,
        serviceLevel: this.evaluate.serviceLevel,
        logisticsLevel: this.evaluate.logisticsLevel,
        deliveryLevel: this.evaluate.deliveryLevel,
        evaluationContent: this.evaluate.evaluationContent,
        loginType: "cs"
      };
      saveEvaluate(params).then(res => {
        if (res.data == "success") {
          this.$notification.success({
            message: "评价成功",
            duration: 3 //延时2.5s
          });
          // this.isDisabled = true
        } else {
          this.$notification.error({
            message: "评价失败",
            duration: 3 //延时2.5s
          });
        }
        // this.getEvaluate();
      });
    },
    getEvaluate() {
      this.evaluate.productLevel = 3;
      this.evaluate.serviceLevel = 3;
      this.evaluate.logisticsLevel = 3;
      this.evaluate.deliveryLevel = 3;
      this.evaluate.evaluationContent = "";
      this.evaluate.finished = false;
      this.evaluate.show = false;
      const params = {
        erpId: this.selSapOrder.id,
        orderId: this.selSapOrder.orderId,
        orgId: this.selSapOrder.orgId
      };
      getEvaluate(params).then(res => {
        if (res.data.productEvaluate) {
          this.evaluate.productLevel = parseInt(
            res.data.productEvaluate.productLevel
          );
          this.evaluate.serviceLevel = parseInt(
            res.data.productEvaluate.serviceLevel
          );
          this.evaluate.logisticsLevel = parseInt(
            res.data.productEvaluate.logisticsLevel
          );
          this.evaluate.deliveryLevel = parseInt(
            res.data.productEvaluate.deliveryLevel
          );
          this.evaluate.evaluationContent =
            res.data.productEvaluate.evaluationContent;
          this.evaluate.creator = res.data.productEvaluate.creator;
          this.evaluate.createdDate = res.data.productEvaluate.createdDate;
          this.evaluate.finished = true;
          this.evaluate.show = true;
        } else {
          if (
            this.selSapOrder.nowStatuses == "O8" ||
            this.selSapOrder.nowStatuses == "O11"
          ) {
            this.evaluate.show = true;
          } else {
            this.evaluate.show = false;
          }
        }
      });
    },
    getOms(orderCode){
      this.pageLoadFlag = true
      this.omsList = []
      findOmsOrderStatus({
        stvNum:orderCode,
        loginType:'',
        cisCode:this.$store.state.user.userInfo.customer.cisCode,
      }).then(responese=>{
        this.omsList = JSON.parse(responese.data).code == 0?JSON.parse(responese.data).data.stvModelObj.omsOrderStatus:[]
        this.pageLoadFlag = false
      })
    },
    // 获取签名
    getSign(data){
      return sign(data)
    },
   async getDetail(id) {
      this.pageLoadFlag = true;
      getPurchaseOrderProcess(id)
        .then(async(res) => {
          if (typeof res.data == "string") {
            this.orderDetail = JSON.parse(res.data).data;
          } else {
            this.orderDetail = res.data.data;
          }
          this.queryAppraise()
          this.sapOrderItems = [];
          this.tabActiveIndex = 0;
          const erpList =[]
          this.orderDetail.outBoundItem.forEach(item => {
            let flag = true
            for(let it of erpList){
              if(it.status==item.status&&it.documentNum==item.documentNum){
                flag=false
                item.sap = item;
                item.sap.xj =(item.sap.bactualQty || 0) * (item.sap.price || 0);
                it.hj=it.hj+item.sap.xj
                it.xjProdNum = it.xjProdNum +(item.sap.bactualQty || 0);
                item.logistics = item.omsOrderStatus;
                // item.nowStatuses = item.omsOrderStatus[0]; //现在订单状态 O8,O11 时可以评价 开头是欧不是零
                // item.nowStatuses = item.omsOrderStatus[0]; //现在订单状态 O8,O11 时可以评价 开头是欧不是零
                it.prods.push(item)
              }
            }
            if(flag){
              item.sap = item;
              item.sap.xj = (item.sap.bactualQty || 0) * (item.sap.price || 0);
              item.hj =  item.sap.xj
              item.xjProdNum = (item.sap.bactualQty || 0);
              item.logistics = item.omsOrderStatus;
              // item.nowStatuses = item.omsOrderStatus[0]; //现在订单状态 O8,O11 时可以评价 开头是欧不是零
              item.prods=[item]
              erpList.push(item);
            }
          });
          this.erpList = erpList
          this.selSapOrder = this.erpList[0];
          const signData = await this.getSign({content:this.orderDetail.isProtoTyp?this.orderDetail.soNum:this.selSapOrder.sap.documentNum,systemSource:'DMS'})
          if(this.orderDetail.isProtoType){
            // this.mapUrl=`https://dwldev.hisense.com/route/index.html#/?orderNumber=${ this.orderDetail.soNum }&systemSource=DMS&sign=${signData.data?signData.data:''}`  
            this.mapUrl=` https://tms.hisense.com/route/index.html#/?orderNumber=${ this.orderDetail.soNum }&systemSource=DMS&sign=${signData.data?signData.data:''}`  
           
          } else {
            // this.mapUrl=`https://dwldev.hisense.com/route/index.html#/?orderNumber=${ this.selSapOrder.sap.documentNum}&systemSource=DMS&sign=sign=${signData.data?signData.data:''}`  
            this.mapUrl=`https://tms.hisense.com/route/index.html#/?orderNumber=${ this.selSapOrder.sap.documentNum}&systemSource=DMS&sign=${signData.data?signData.data:''}`  
           
          }

          if (this.selSapOrder && this.selSapOrder.sap) {
            this.isOuted = !!this.selSapOrder.sap.documentNum;
          }
          if(this.isOuted){
            this.requestImg(this.selSapOrder.sap.documentNum);
          }
          // 获取物流信息
          this.getOms(this.selSapOrder.sap.documentNum )
          // if(this.selSapOrder.sap.billPrice.toString().indexOf('.')=='-1'){
          //   this.selSapOrder.sap.billPrice = Number(this.selSapOrder.sap.billPrice).toFixed(2)
          // }
          // if(this.selSapOrder.sap.basePrice.toString().indexOf('.')=='-1'){
          //   this.selSapOrder.sap.basePrice = Number(this.selSapOrder.sap.basePrice).toFixed(2)
          // }
          // this.getEvaluate()
        })
        .finally(() => {
          this.pageLoadFlag = false;
        });
      // getExamInfo(id).then(res => {
      //   if (typeof res.data == "string") {
      //     this.examInfo = JSON.parse(res.data).data;
      //   } else {
      //     this.examInfo = res.data.data;
      //   }
      // });
    },
    goBack() {
      // this.$router.go(-1);
      window.close()
    },
    async tabChange(index) {
      this.tabActiveIndex = index;
      this.selSapOrder = this.erpList[index];
      //  获取签名
      const signData = await this.getSign({content:this.orderDetail.isProtoTyp?this.orderDetail.soNum:this.selSapOrder.sap.documentNum,systemSource:'DMS'})    
      if(this.orderDetail.isProtoType){
        this.mapUrl=`https://tms.hisense.com/route/index.html#/?orderNumber=${ this.orderDetail.soNum }&systemSource=DMS&sign=${signData.data?signData.data:''}`  
        // this.mapUrl=`https://dwldev.hisense.com/route/index.html#/?orderNumber=${ this.orderDetail.soNum }&systemSource=DMS&sign=${signData.data?signData.data:''}`  
      } else {
        this.mapUrl = `https://tms.hisense.com/route/index.html#/?orderNumber=${ this.selSapOrder.sap.documentNum}&systemSource=DMS&sign=${signData.data?signData.data:''}`
      
        // this.mapUrl = `https://dwldev.hisense.com/route/index.html#/?orderNumber=${ this.selSapOrder.sap.documentNum}&systemSource=DMS&sign=${signData.data?signData.data:''}`
      }

      this.isOuted = !!this.selSapOrder.sap.documentNum;
      if(this.isOuted){
        this.requestImg(this.selSapOrder.sap.documentNum);
      }
      this.showImg = false;
      this.$nextTick(it=>{
        this.showImg = true
      })
      this.getOms(this.selSapOrder.sap.documentNum )
    },
    handleOk(e) {
      this.visible = false;
    },
    changeChecked(item) {
      item.val = !item.val;
    },
    getPrice(item) {
      return ((item.basePrice || 0) * 100 * (item.qty || 0)) / 100;
    },
    copyOrder() {
      var clipboard = new Clipboard(".copy-box");
      clipboard.on("success", e => {
        this.$message.success("复制成功");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", e => {
        // 不支持复制
        this.$message.warning("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    }
  }
};